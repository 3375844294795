.header {
  margin: 2em 1em;
}

.headerText {
  text-align: center;
  text-transform: uppercase;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
