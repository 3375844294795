.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0 1em;
  padding: 0;
}

.lastRowSpacer {
  display: none;
}

@media (min-width: 800px) {
  .grid {
    margin: 0 auto;
    width: 90%;
  }
}

@media (min-width: 950px) {
  .lastRowSpacer {
    /* 
    Designed to prevent one item on the effective last row taking up the entire width -> and therefore having massive height
    Can result in a rows that doesn't look complete depending on viewport size.    
    */
    min-width: 35%;
    flex-grow: 1;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    display: block;
  }
}

@media (min-width: 1200px) {
  .grid {
    margin: 0 auto;
    width: 80%;
  }
}
