.container {
  position: relative;
}

.spacer {
  display: block;
}

.gridImg {
  position: absolute;
  top: 0;
  width: 100%;
  vertical-align: bottom;
  border-radius: 2px;
}
